$main-white: #ffffff;
$main-bg: #f2f2f2;

// text color
$main-dark: #293845;
$main-gray: rgba($main-dark, 0.5);
$main-light-gray: rgba($main-dark, 0.3);

$main-blue: #b2b5b6;
$main-red: #d0021b;

$main-blue-disable: rgba($main-blue, 0.5);

$main-purple: #9013fe;
$main-darkpurple: #5e2563;
$main-dark-red: #d0021b;
$main-yellow: #f8e71c;
$main-orange: #f5a623;
$main-green: #7ed321;

$card-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.02);
$card-shadow-sm: 0 0 7px 3px rgba(0, 103, 175, 0.1);
$panel-shadow: 1px 3px 17px 0 rgba(0, 0, 0, 0.1);

$border: #979797;
$border-light: rgba($border, 0.1);

$blue-hover: rgba($main-blue, 0.2);
$blue-active: rgba($main-blue, 0.5);

$table-stripe: rgba($main-dark, 0.02);

$main-disabled: #eff6ff;

// Breakpoints

// Defining values
$screen-xs-min: 321px;
$screen-xxs-max: ($screen-xs-min - 1);
$screen-sm-min: 600px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-ipad-min: 768px;
$screen-ipad-max: ($screen-ipad-min - 1);
$screen-md-min: 960px;
$screen-sm-max: ($screen-md-min - 1);
$screen-lg-min: 1280px;
$screen-md-max: ($screen-lg-min - 1);
$screen-xl-min: 1920px;
$screen-lg-max: ($screen-lg-min - 1);
