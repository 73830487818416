.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates three columns of equal width */
  grid-gap: 16px; /* Adjust the gap size as needed */
  justify-content: center; /* Center the grid within the parent */
  align-items: start; /* Align grid items to the start of each cell */
}

.grid-item {
  /* Add styles for the grid item if needed, for example: */
  width: 100%; /* Full width of the grid column */
  aspect-ratio: 1; /* You can set an aspect ratio if you want square items */
}
