@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700,800');
@import url('https://fonts.googleapis.com/css?family=Share:300,400,500,700,800');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rany:wght@500&display=swap');

@font-face {
  font-family: 'Rany';
  src: url('../assets/fonts/rany/Rany.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rany';
  src: url('../assets/fonts/rany/RanyLight.otf') format('opentype');
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: 'Rany';
  src: url('../assets/fonts/rany/RanyBold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Rany';
  src: url('../assets/fonts/rany/RanyMedium.otf') format('opentype');
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: 'Rany';
  src: url('../assets/fonts/rany/RanyItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

/* Set the root font size to a base value with additional scaling based on the viewport width */
html {
  font-size: calc(14px + (16 - 14) * ((100vw - 1440px) / (1920 - 1440)));
}

/* Ensures that the font size doesn't scale down on smaller viewports */
@media (max-width: 1440px) {
  html {
    font-size: 14px;
  }
}

/* Ensures that the font size doesn't scale indefinitely on larger viewports */
@media (min-width: 1920px) {
  html {
    font-size: 16px;
  }
}

// Base
@import 'base/variables';
@import 'base/typography';
@import 'base/base';
@import 'base/helpers';

// Components
@import 'components/roundedButton';

/*
@import 'components/roundedButton';
@import 'components/input';
@import 'components/buttonGroup';
@import 'components/mobileMenu';
@import 'components/topHeader';
@import 'components/card';
@import 'components/form';
@import 'components/panel';
@import 'components/page';
@import 'components/select';
@import 'components/sidebar';
@import 'components/stepper';
@import 'components/uploader';
@import 'components/footer';
@import 'components/signup';
@import 'components/toaster';
@import 'components/table';
@import 'components/tutorial';
@import 'components/subscribe';
@import 'components/preloader';
@import 'components/brandCard';
*/
// Pages
// @import 'pages/home';
@import 'pages/location';
