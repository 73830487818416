.btn {
  border-radius: 100px;
  box-shadow: 0 0 7px 3px rgba(0, 103, 175, 0.1);
  overflow: hidden;
  transition: all 0.2s ease;
  border-radius: 9px !important;
  &:hover {
    background-color: #cae3f9;
  }

  span {
    font-size: 13px;
    color: $main-blue;
  }

  &.btn-small {
    min-width: 50px;
    min-height: 20px;

    span {
      font-size: 12px;
    }
  }

  &.btn-large {
    height: 48px;
    line-height: 48px;
    min-width: 100px;

    span {
      font-size: 16px;
      line-height: 1;
    }
  }

  &.btn-text {
    box-shadow: none;
    color: $main-white;
  }

  &[disabled] {
    box-shadow: none;
    background-color: $main-bg;

    span {
      color: $main-light-gray;
    }
  }

  &-badge {
    position: absolute;
    top: 6px;
    right: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    color: $main-white;
    font-size: 12px;
    font-weight: 500;
    background-color: $main-red;
    overflow: hidden;
  }
}

.button-bounce {
  position: relative;
  overflow: visible;
  animation: button-bounce 2500ms forwards infinite
    cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: background-color 0.3s ease;

  &:after {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    background: $main-blue;
    animation: button-blip 2500ms forwards infinite
      cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  &[disabled] {
    animation: none;

    &:after {
      animation: none;
      background: transparent;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .btn {
    &.btn-large {
      height: 36px;
      line-height: 36px;

      span {
        font-size: 13px;
      }
    }
  }
}

@keyframes button-bounce {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.96);
  }
  20% {
    transform: scale(1.04);
  }
  52%,
  100% {
    transform: scale(1);
  }
}

@keyframes button-blip {
  0% {
    transform: scaleX(1) scaleY(1);
    opacity: 0;
  }
  10% {
    transform: scaleX(1) scaleY(1);
    opacity: 0.7;
  }
  30%,
  99% {
    transform: scaleX(1.08) scaleY(1.35);
    opacity: 0;
  }
  100% {
    transform: scaleX(1) scaleY(1);
    opacity: 0;
  }
}
