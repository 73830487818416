body {
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  display: flex;
  font-size: 14px;
  flex-direction: column;
  flex: 1;
  padding: 0;
  margin: 0;

  &-center {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
}

.wrapper {
  position: relative;
  min-height: 100vh;
  background-color: $main-bg;
  padding-bottom: 48px;

  &-sub {
    position: relative;
    padding-top: 58px;
    padding-bottom: 48px;
    min-height: calc(100vh - 78px);
  }
}

.full-page {
  //min-height: calc(100vh - 78px);
  min-height: 100vh;
  padding-top: 1px;
  &-center {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  &-gray-bg {
    background-color: $main-bg;
    margin: 0% !important;
  }
}

.grid-wrapper {
  padding: 24px;
}

.square-btn {
  height: 40px;
  width: 40px;
  box-shadow: $card-shadow-sm;
  border-radius: 4px;

  &.square-lg {
    height: 56px;
    width: 56px;
  }

  span {
    border-radius: 4px;
  }
}

.btn {
  &-input {
    font-size: 12px;
    white-space: nowrap;
    color: $main-blue;
    cursor: pointer;
  }
}

.rounded-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #f7f7f8;
  //box-shadow: 0 3px 6px 1px rgba(4, 44, 71, 0.1);

  img {
    width: 28px;
    height: 28px;
  }
}

.circle-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid rgba($border, 0.3);
  border-radius: 50%;

  svg,
  img {
    width: 18px;
    fill: $border;
  }
}

.item {
  &-center {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    &-start {
      justify-content: flex-start;
    }
  }

  &-left {
    display: flex;
    justify-content: flex-start !important;
  }
}

.tr-error {
  &-message {
    font-size: 12px;
    color: $main-red;
  }

  &-no-height {
    height: 0;
    position: relative;
    top: -12px;
  }
}

.tr-santa {
  // background-image: url('~/assets/images/santa-2.gif');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: calc(0% - 200px);

  &:hover {
    background-position-x: calc(100% + 200px);
    transition: 7s background-position-x 5s linear;
  }

  &-welcome {
    position: relative;
    height: 205px;

    &:before {
      content: '';
      position: absolute;
      // background-image: url('~/assets/images/santa-1.gif');
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: 300px;
      width: calc(100% - 60px);
      height: 205px;
      opacity: 0;
    }

    &:hover {
      height: 450px;
      background-color: #46426e !important;
      transition: 1s all 5s ease;

      &:before {
        bottom: 0;
        opacity: 1;
        height: 250px;
        transition: 2s all 6s ease;
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  .wrapper {
    padding-top: 58px;

    .top-header-combo {
      position: fixed;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .grid-wrapper {
    padding: 8px;
    margin-top: 16px;
    width: calc(100% - 4px);
  }
}

@media only screen and (min-width: 960px) {
  .mt--10 {
    margin-top: -20px;
  }
}

.tr-code-input {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    font-size: 22px;
    -moz-appearance: textfield;
    width: 40px;
    margin: 8px;
    border-width: 0;
    border-bottom-width: 1px;
    border-bottom-color: rgba(04, 44, 71, 0.35);
    text-align: center;
  }
}

.tr-code-input-error {
  input {
    color: $main-red;
    border-bottom-color: $main-red;
  }
}

/* hide up/down arrows ("spinners") on input fields marked type="number" */
.no-spinners {
  -moz-appearance: textfield;
}

.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
